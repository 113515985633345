var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-card',{attrs:{"title":_vm.$t('work.headerDescriptionOfWork')}},[_c('b-row',[(_vm.userData.userCategory === 1)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.company') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"companyName","options":_vm.optionCompanies,"reduce":function (option) { return option.companyId; }},model:{value:(_vm.companyId),callback:function ($$v) {_vm.companyId=$$v},expression:"companyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(_vm.userData.userCategory === 1)?_c('b-col',{attrs:{"md":"6"}}):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workType') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.workType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"options":_vm.optionWorkTypes,"value-field":"workTypeId","text-field":"workTypeName"},model:{value:(_vm.workTypeId),callback:function ($$v) {_vm.workTypeId=$$v},expression:"workTypeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workScope') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.workScope'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.workScope),callback:function ($$v) {_vm.workScope=$$v},expression:"workScope"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.location') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.location'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"locationName","options":_vm.optionLocations,"reduce":function (option) { return option.locationId; }},model:{value:(_vm.locationId),callback:function ($$v) {_vm.locationId=$$v},expression:"locationId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.department') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.department'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"departmentName","options":_vm.optionDepartments,"reduce":function (option) { return option.departmentId; }},model:{value:(_vm.departmentId),callback:function ($$v) {_vm.departmentId=$$v},expression:"departmentId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-card',{attrs:{"title":_vm.$t('work.headerDateOfWork')}},[_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":_vm.addWorkDay}},[_vm._v(_vm._s(_vm.$t('work.btnAddWorkingDay')))])],1),_vm._l((_vm.workingDayLists),function(row,key){return _c('div',{key:'workDay-' + key,staticClass:"multiple-row"},[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"mb-1"},[_vm._v("#"+_vm._s(row.workNum))])]),_c('b-col',{staticClass:"text-right"},[(_vm.workingDayLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteWorkDay(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workingDayType')}},[_c('b-form-radio-group',{model:{value:(row.workDayType),callback:function ($$v) {_vm.$set(row, "workDayType", $$v)},expression:"row.workDayType"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('work.radioWorkingDay')))]),_c('b-form-radio',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('work.radioHoliday')))])],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workingDay') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.workingDay'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.datePickerConfig},model:{value:(row.workDate),callback:function ($$v) {_vm.$set(row, "workDate", $$v)},expression:"row.workDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.timeOfWork')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.main.timePickerConfig},model:{value:(row.workStartTime),callback:function ($$v) {_vm.$set(row, "workStartTime", $$v)},expression:"row.workStartTime"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.to')}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.$store.state.main.timePickerConfig},model:{value:(row.workEndTime),callback:function ($$v) {_vm.$set(row, "workEndTime", $$v)},expression:"row.workEndTime"}})],1)],1)],1)],1)})],2),_c('b-card',{attrs:{"title":_vm.$t('work.headerContractor')}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.supervisor') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.supervisor'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userFullName","options":_vm.optionContractorAdminLists,"reduce":function (option) { return option.userId; },"selectable":function (option) { return !_vm.optionContractorAdminListsDisabled.includes(option.userId); }},model:{value:(_vm.workContractorAdminId),callback:function ($$v) {_vm.workContractorAdminId=$$v},expression:"workContractorAdminId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.tel') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.tel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.workContractorAdminTel),callback:function ($$v) {_vm.workContractorAdminTel=$$v},expression:"workContractorAdminTel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addWorker}},[_vm._v(_vm._s(_vm.$t('work.btnAddWorker')))])],1)],1),_vm._l((_vm.workerLists),function(row,key){return _c('b-row',{key:'worker' + key,staticClass:"multiple-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"cols":"4","order":"1","md":"2","lg":"1"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.order')}},[_c('b-form-input',{attrs:{"readonly":""},model:{value:(row.workerNum),callback:function ($$v) {_vm.$set(row, "workerNum", $$v)},expression:"row.workerNum"}})],1)],1),_c('b-col',{attrs:{"cols":"12","order":"3","md":"12","lg":"10","order-lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.workers') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.workers'),"rules":"required","vid":'workerUserId' + key},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userFullName","options":_vm.optionWorkerLists,"reduce":function (option) { return option.userId; },"selectable":function (option) { return !_vm.optionWorkerListsDisabled.includes(option.userId) && !_vm.getWorkerSelected.includes(option.userId); }},model:{value:(row.userId),callback:function ($$v) {_vm.$set(row, "userId", $$v)},expression:"row.userId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"8","order":"2","md":"10","lg":"1","order-lg":"4"}},[(_vm.workerLists.length > 1)?_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.deleteWorker(key)}}},[_vm._v(" "+_vm._s(_vm.$t('general.btnDelete'))+" ")]):_vm._e()],1)],1)}),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('work.totoWorkOwner') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('work.totoWorkOwner'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"userFullName","options":_vm.optionWorkOwners,"reduce":function (option) { return option.userId; }},model:{value:(_vm.workWorkOwnerId),callback:function ($$v) {_vm.workWorkOwnerId=$$v},expression:"workWorkOwnerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],2),_c('b-row',[_c('b-col',{staticClass:"text-right"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"ChevronRightIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnNext'))+" ")],1)],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }