<template>
  <div>
    <b-overlay :show="isShowLoading">
      <validation-observer ref="validateForm" #default="{invalid}">
        <b-form @submit.prevent="saveData">

          <b-card :title="$t('work.headerDescriptionOfWork')">
            <b-row>
              <b-col md="6" v-if="userData.userCategory === 1">
                <b-form-group :label="$t('work.company') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.company')" rules="required">
                    <v-select v-model="companyId" label="companyName"
                              :options="optionCompanies" :reduce="option => option.companyId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="userData.userCategory === 1"></b-col>

              <b-col md="12">
                <b-form-group :label="$t('work.workType') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.workType')" rules="required">
                    <b-form-checkbox-group v-model="workTypeId" :options="optionWorkTypes" value-field="workTypeId" text-field="workTypeName" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group :label="$t('work.workScope') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.workScope')" rules="required">
                    <b-form-input v-model="workScope" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('work.location') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.location')" rules="required">
                    <v-select v-model="locationId" label="locationName"
                              :options="optionLocations" :reduce="option => option.locationId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('work.department') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.department')" rules="required">
                    <v-select v-model="departmentId" label="departmentName"
                              :options="optionDepartments" :reduce="option => option.departmentId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
          </b-card>

          <b-card :title="$t('work.headerDateOfWork')">
            <div class="text-right">
              <b-button variant="primary" class="mb-1" @click="addWorkDay">{{ $t('work.btnAddWorkingDay') }}</b-button>
            </div>

            <div class="multiple-row" v-for="(row, key) in workingDayLists" :key="'workDay-' + key">
              <b-row>
                <b-col>
                  <h5 class="mb-1">#{{ row.workNum }}</h5>
                </b-col>

                <b-col class="text-right">
                  <b-button variant="danger" size="sm" @click="deleteWorkDay(key)" v-if="workingDayLists.length > 1">
                    {{ $t('general.btnDelete') }}
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="6">
                  <b-form-group :label="$t('work.workingDayType')">
                    <b-form-radio-group v-model="row.workDayType">
                      <b-form-radio value="1">{{ $t('work.radioWorkingDay') }}</b-form-radio>
                      <b-form-radio value="2">{{ $t('work.radioHoliday') }}</b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.workingDay') + '*'">
                    <validation-provider #default="{ errors }" :name="$t('work.workingDay')" rules="required">
                      <flat-pickr v-model="row.workDate" class="form-control" :config="datePickerConfig"/>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.timeOfWork')">
                    <flat-pickr v-model="row.workStartTime" class="form-control" :config="$store.state.main.timePickerConfig"/>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group :label="$t('work.to')">
                    <flat-pickr v-model="row.workEndTime" class="form-control" :config="$store.state.main.timePickerConfig"/>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-card :title="$t('work.headerContractor')">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('work.supervisor') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.supervisor')" rules="required">
                    <v-select v-model="workContractorAdminId" label="userFullName"
                              :options="optionContractorAdminLists" :reduce="option => option.userId"
                              :selectable="option => !optionContractorAdminListsDisabled.includes(option.userId)"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('work.tel') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.tel')" rules="required">
                    <b-form-input v-model="workContractorAdminTel" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-right">
                <b-button variant="primary" @click="addWorker">{{ $t('work.btnAddWorker') }}</b-button>
              </b-col>
            </b-row>

            <b-row v-for="(row, key) in workerLists" :key="'worker' + key" align-v="center" class="multiple-row">
              <b-col cols="4" order="1" md="2" lg="1">
                <b-form-group :label="$t('work.order')">
                  <b-form-input v-model="row.workerNum" readonly=""/>
                </b-form-group>
              </b-col>

              <b-col cols="12" order="3" md="12" lg="10" order-lg="2">
                <b-form-group :label="$t('work.workers') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.workers')" rules="required" :vid="'workerUserId' + key">
                    <v-select v-model="row.userId" label="userFullName"
                              :options="optionWorkerLists" :reduce="option => option.userId"
                              :selectable="option => !optionWorkerListsDisabled.includes(option.userId) && !getWorkerSelected.includes(option.userId)"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="8" order="2" md="10" lg="1" order-lg="4" class="text-right">
                <b-button variant="danger" size="sm" @click="deleteWorker(key)" v-if="workerLists.length > 1">
                  {{ $t('general.btnDelete') }}
                </b-button>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('work.totoWorkOwner') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('work.totoWorkOwner')" rules="required">
                    <v-select v-model="workWorkOwnerId" label="userFullName"
                              :options="optionWorkOwners" :reduce="option => option.userId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

          </b-card>

          <b-row>
            <b-col class="text-right">
              <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                <b-spinner small v-if="isBtnDisabled"/>
                <feather-icon icon="ChevronRightIcon" v-else/>
                {{ $t('general.btnNext') }}
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>

    </b-overlay>
  </div>

</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BOverlay,
  BRow,
  BSpinner
} from 'bootstrap-vue'
import {required} from '@validations'
import moment from 'moment-timezone'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import formMixins from "@/mixins/formMixins"
import {ValidateService, WorkService} from "@/services"

moment.tz.setDefault("Asia/Bangkok")

let workService = new WorkService()
let validateService = new ValidateService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormFile,
    BButton,
    BSpinner,
    BOverlay,
    BFormRadio,
    BFormRadioGroup,
  },
  mixins: [tableMixins, masterMixins, formMixins],
  async created() {
    let [
      listPrefixes,
      listCompany,
      listPositions,
      listLocations,
      // listDepartments,
      listWorkTypes,
      listWorkOwner,
    ] = await Promise.all([
      this.listPrefix(),
      this.listCompany(),
      this.listPosition(),
      this.listLocation(),
      // this.listDepartment(),
      this.listWorkType(),
      this.listUserWorkOwner(),
    ])
    this.optionPrefixes = listPrefixes
    this.optionCompanies = listCompany
    this.optionPositions = listPositions
    this.optionLocations = listLocations
    // this.optionDepartments = listDepartments
    this.optionWorkTypes = listWorkTypes
    this.optionWorkOwners = listWorkOwner

    this.$store.commit('work/setWorkType', listWorkTypes)

    this.id = this.$route.params.id

    if(this.id) {
      await this.getData()

      this.listDepartment({locationId: this.locationId})
          .then(results => {
            this.optionDepartments = results
            this.departmentId = this.initDepartmentId
            this.isShowLoading = false


          })
    } else {
      this.companyId = this.userData.companyId

      this.addWorker()
      this.addWorkDay()
    }

    let workStore = this.$store.state.work.step1
    if(Object.entries(workStore).length > 0) {
      this.companyId = workStore.companyId
      this.workTypeId = workStore.workTypeId
      this.workScope = workStore.workScope
      this.departmentId = workStore.departmentId
      this.locationId = workStore.locationId
      this.workContractorAdminId = workStore.workContractorAdminId
      this.workContractorAdminTel = workStore.workContractorAdminTel
      this.workWorkOwnerId = workStore.workWorkOwnerId
      this.workingDayLists = workStore.workingDayLists
      this.workerLists = workStore.workerLists
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  beforeDestroy() {
    if(this.$route.name !== 'pageWorkAddStep2' && this.$route.name !== 'pageWorkEditStep2') {
      this.$store.commit('work/setStep1', {})
    }
  },
  destroyed() {
    let config = this.$store.state.main.datePickerConfig
    config.mode = 'single'
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    userData: JSON.parse(localStorage.getItem('userData')),
    id: '',
    companyId: '',
    workTypeId: [],
    workScope: '',
    initDepartmentId: '',
    departmentId: '',
    locationId: '',
    workContractorAdminId: '',
    workContractorAdminTel: '',
    workWorkOwnerId: '',
    /*companyId: '',
    workTypeId: [1, 2],
    workScope: 'ติดตั้งไฟฟ้า, ระบบ safety',
    departmentId: 32,
    locationId: 2,
    workContractorAdminId: 14,
    workContractorAdminTel: 'bb',
    workWorkOwnerId: 15,*/
    workingDayLists: [],
    workerLists: [],

    optionPrefixes: [],
    optionWorkTypes: [],
    optionDepartments: [],
    optionCompanies: [],
    optionLocations: [],
    optionPositions: [],
    optionWorkOwners: [],
    optionContractorAdminLists: [],
    optionWorkerLists: [],
    optionContractorAdminListsDisabled: [],
    optionWorkerListsDisabled: [],
  }),
  watch: {
    companyId(newVal) {
      if (newVal) {
        let promiseQuery = [
          this.listUserContractorAdmin(newVal),
          this.listUserContractor(newVal),
        ]

        Promise
            .all(promiseQuery)
            .then(results => {
              let [listContractorAdmin, listWorker] = results
              this.optionContractorAdminLists = listContractorAdmin
              this.optionWorkerLists = listWorker
              this.setDisabledWorker()
              this.setDisabledWorkerAdmin()

              this.optionWorkerLists = listWorker.map(data => {
                if(this.optionWorkerListsDisabled.includes(data.userId)) {
                  let setNewData = data
                  setNewData.userFullName = `${setNewData.userFullName} (ไม่มีใบอนุญาต, หมดอายุ, หรือติด Blacklist)`

                  return setNewData
                } else {
                  return data
                }
              })

              this.optionContractorAdminLists = listContractorAdmin.map(data => {
                if(this.optionContractorAdminListsDisabled.includes(data.userId)) {
                  let setNewData = data
                  setNewData.userFullName = `${setNewData.userFullName} (ไม่มีใบอนุญาต, หมดอายุ, หรือติด Blacklist)`

                  return setNewData
                } else {
                  return data
                }
              })
            })
      } else {
        this.optionContractorAdminLists = []
        this.optionWorkerLists = []

        this.setDisabledWorker()
        this.setDisabledWorkerAdmin()
      }
    },
    workContractorAdminId(newVal) {
      if (newVal && !this.isShowLoading) {
        let result = this.optionContractorAdminLists.find(user => user.userId === newVal)
        this.workContractorAdminTel = result ? result.userTel : ''
      }
    },
    locationId(nVal) {
      this.departmentId = ''
      this.isShowLoading = true
      this.listDepartment({locationId: nVal})
          .then(results => {
            this.optionDepartments = results
            this.isShowLoading = false
          })
    }
  },
  computed: {
    datePickerConfig() {
      let config = this.$store.state.main.datePickerConfig
      config.mode = 'range'
      return config
    },
    getWorkerSelected() {
      return this.workerLists.map(data => data.userId)
    }
  },
  methods: {
    async getData() {
      let queryResult = await workService.getData(this.id)
      if(queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data

        this.companyId = result.companyId
        this.workTypeId = result.workTypeId.split(',').map(workTypeId => parseInt(workTypeId))
        this.workScope = result.workScope
        this.initDepartmentId = result.departmentId
        this.departmentId = result.departmentId
        this.locationId = result.locationId
        this.workContractorAdminId = result.workContractorAdminId
        this.workContractorAdminTel = result.workContractorAdminTel
        this.workWorkOwnerId = result.workWorkOwnerId
        this.workingDayLists = result.workingDayLists.map(data => {
          return {
            workDayId: data.workDayId,
            workDayType: data.workIsHoliday,
            workDate: data.workDate,
            workStartTime: data.workStartTime,
            workEndTime: data.workEndTime,
            workNum: data.workNum,
          }
        })
        this.workerLists = result.workerLists.map((data, key) => {
          return {
            userId: data.userId,
            workerNum: key + 1,
            workerId: data.workerId
          }
        })
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterDepartment'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        let checkDateResult = await this.checkWorkDate()
        if (success && checkDateResult) {
          this.isBtnDisabled = true
          let actionData = {
            companyId: this.companyId,
            workTypeId: this.workTypeId,
            workScope: this.workScope,
            departmentId: this.departmentId,
            locationId: this.locationId,
            workContractorAdminId: this.workContractorAdminId,
            workContractorAdminTel: this.workContractorAdminTel,
            workWorkOwnerId: this.workWorkOwnerId,
            workingDayLists: this.workingDayLists,
            workerLists: this.workerLists,
          }
          this.$store.commit('work/setStep1', actionData)
          this.isBtnDisabled = false

          if(this.id) {
            this.$router.push({name: 'pageWorkEditStep2'})
          } else {
            this.$router.push({name: 'pageWorkAddStep2'})
          }
        }
      })
    },
    addWorkDay() {
      this.workingDayLists.push({
        workDayId: '',
        workDayType: '1',
        workDate: '',
        workStartTime: '',
        workEndTime: '',
        workNum: this.workingDayLists.length + 1,
      })
    },
    deleteWorkDay(row) {
      this.workingDayLists.splice(row, 1)
      this.workingDayLists = this.workingDayLists.map((data, key) => ({...data, workNum: key + 1}))
    },
    addWorker() {
      this.workerLists.push({
        userId: '',
        workerNum: this.workerLists.length + 1,
        workerId: '',
      })
    },
    deleteWorker(row) {
      this.workerLists.splice(row, 1)
      this.workerLists = this.workerLists.map((data, key) => ({...data, workerNum: key + 1}))
    },
    setDisabledWorker() {
      this.optionWorkerListsDisabled = this.optionWorkerLists
          .filter(data => data.userStatus !== 'Y' || !data.userLicenseEndDate ||
              !moment(moment(data.userLicenseEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD')))
          .map(data => data.userId)
    },
    setDisabledWorkerAdmin() {
      this.optionContractorAdminListsDisabled = this.optionContractorAdminLists
          .filter(data => data.userStatus !== 'Y' || !data.userLicenseEndDate ||
              !moment(moment(data.userLicenseEndDate, 'DD/MM/YYYY').format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD')))
          .map(data => data.userId)
    },
    async checkWorkDate() {
      let result = await validateService.checkWorkDate({
        workingDayLists: this.workingDayLists,
      })

      if(!result.isSuccess) {
        this.$store.commit('main/setToastError', result.data)
      }

      return result.isSuccess
    }
  }
}
</script>

<style lang="scss">
</style>
